import { defineStore } from 'pinia'
import {aSyncData, stringToDate} from "@/modules/main";

export const userStore = defineStore('user', {
    state: () => ({
        id: -1,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        avatar: '',
        credits: 0,
        geo: false,
        lounges: [],
        geoCity: {},
        ipCity: {}
    }),
    getters: {
      activeLounges(){
          return this.lounges.filter(l => {
              try {
                  return stringToDate(l.startTime).getTime() > Date.now() && stringToDate(l.endTime).getTime() < (Date.now() + 1000*60*60*2)
              }catch (e) {
                  return false
              }
          })
      }
    },
    actions: {
        async getCityByGeo() {
            const _this = this
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(geo => {
                    _this.geo = geo
                    aSyncData('home/getCityByGPS', {lat: geo.coords.latitude, lon: geo.coords.longitude})
                        .then(r => {
                            console.log(r)
                            if(r.status === 250) {
                                _this.geoCity = r.data
                                resolve(_this.geoCity)
                            }else{
                                reject(r.status)
                            }
                        })
                        .catch( e => {
                            console.error(e)
                            reject(e)
                        })
                }, e => {
                    console.log(e)
                    reject(e)
                })
            })
        },
        getCityByIP(){
            const _this = this
            return new Promise((resolve, reject) => {
                console.log('ip')
                aSyncData('home/getCityByIP')
                    .then(r => {
                        console.log(r)
                        if(r.status === 250) {
                            _this.ipCity = r.data
                            resolve(_this.ipCity)
                        }else{
                            reject(r.status)
                        }
                    })
                    .catch( e => {
                        console.error(e)
                        reject(e)
                    })
            })
        }
    }
})