<template>
  <article class="col-sp-4 row-sp-12 pad-8" @click="$router.push({name: 'Suche', params: { id: cityItems.id }});">
    <div class="cityItem" :style="{'background-image':'url(assets/images/cities/'+cityItems.image+')'}">
      <div class="cover">
        <h6>{{ cityItems.name }}</h6>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "CityItems",
  props: ["cityItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style scoped>
.cityItem{
  background-size: cover;
  border-radius: 10px;
  border: 4px solid #ffffff;
  cursor: pointer;
}
.cityItem .cover{
  padding-top: 80%;
  background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.85));
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000",endColorstr="#a6000000",GradientType=0);
  color: rgba(255,255,255,1);
  border-radius: 10px;
  overflow: hidden;
}
.cityItem .cover h6{
  padding: 0 0 8px 4px;
  font-weight: 600;
  text-align: center;
}
</style>