<template>
  <main class="content-500">
    <section id="walletHeader">
      <div class="pad-16">
        <div class="grid-12">
          <div class="col-sp-8 row-sp-12">
            <h1>Dein Guthaben</h1>
          </div>
          <div class="col-sp-4 row-sp-12 t-right">
            <button class="button bgcolor-blue color-white">
              {{ (user.credits / 100).toFixed(2) }}€
            </button>
          </div>
        </div>
      </div>
    </section>
    <section id="walletCTA">
      <div class="pad-8">
        <div class="grid-12">
          <div class="col-sp-6 row-sp-12 pad-8">
            <button @click="generatePayQRCode()" class="button bgcolor-blue color-white" id="pay">
              <img src="assets/images/icons/pay-white.svg" class="width-80">
              <h4>Bezahlen</h4>
            </button>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <button @click="checkOutModal = true" class="button bgcolor-second color-white" id="charge">
              <img src="assets/images/icons/charge-white.svg" class="width-80">
              <h4>Guthaben aufladen</h4>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section id="paymentHistory">
      <div class="pad-16">
        <h2>Deine letzten Transaktionen</h2>
        <div v-if="loadingTransaction">
          lädt...
        </div>
        <div class="pad-32 t-center" v-else-if="!transactions.length">
          <p>Keine Transaktionen vorhanden</p>
        </div>
        <div v-else>
          <div v-for="(transaction, index) in transactions" :key="index">
            <div class="paymentItem" v-if="transaction.amount !== 0">
              <div class="paymentItemAmount">
                <div class="grid-12">
                  <div class="col-sp-8 row-sp-12 t-left">
                    <p>{{ getTransactionTitle(transaction) }} <span style="font-size: 0.7em">#{{ transaction.id }}</span></p>
                  </div>
                  <div class="col-sp-4 row-sp-12 t-right" :class="transaction.amount < 0 ? 'negative' :'positive'">
                    {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(transaction.amount / 100) }}
                  </div>
                </div>
              </div>
              <div class="paymentItemDate">
                {{ transaction.date }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <transition name="modal" class="paymentModal">
      <VueModal :show="paymentModal" @close="paymentModal = false" @cancel="paymentModal = false" min-size="small" max-size="large">
        <div class="t-center" style="overflow: scroll">
          <h2>App<span class="f-bold">Pay</span></h2>
          <h3 class="color-white">
            Zeige diesen Code zum bezahlen vor
          </h3>
          <div class="t-center qrcode" @click="generatePayQRCode">
            <img id="qrious">
          </div>
          <div style="margin-top: 8px;">
            Aktuelles Guthaben: {{ (user.credits / 100).toFixed(2) }}€
          </div>
          <div class="pad-8" />
          <div class="smallHint" style="margin-bottom: 12px;">
            {{ transactionInfo }}
          </div>
          <div class="flex buttons-wrapper">
            <button @click="tip" class="button-primary">
              Trinkgeld?
            </button>
            <button @click="paymentModal = false;" class="button bgcolor-black color-white fluid">
              Abbrechen
            </button>
          </div>
        </div>
      </VueModal>
    </transition>
    <transition name="modal" class="paymentModal">
      <VueModal :show="checkOutModal" @close="checkOutModal = false" @cancel="checkOutModal = false" min-size="small" max-size="large">
        <check-out checkout-type="credits" />
        <div class="pad-4" />
        <button @click="checkOutModal = false;" class="button bgcolor-black color-white fluid">
          Abbrechen
        </button>
      </VueModal>
    </transition>
    <transition name="modal" class="paymentModal">
      <VueModal :show="tipModal" @close="tipModal = false" @cancel="tipModal = false" min-size="small" max-size="large">
        <tip :amount="paymentAmount" :payment-intent-hash="paymentIntent.split('.')[0]" />
        <div class="pad-4" />
        <button @click="tipModal = false;" class="button bgcolor-black color-white fluid">
          Abbrechen
        </button>
      </VueModal>
    </transition>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import Qrious from "qrious";
import CheckOut from "@/components/payment/checkOut";
import {aSyncData} from "@/modules/main";
import Tip from "@/components/payment/tip";

export default {
  name: "Wallet",
  title: "Wallet",
  components: {
    Tip,
    CheckOut
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      paymentModal: false,
      checkOutModal: false,
      tipModal: false,
      chargeAmount: 5.00,
      loadingTransaction: true,
      transactions: [],
      paymentIntent: '',
      transactionInfo: '',
      paymentAmount: 0
    }
  },
  watch: {
    paymentModal(){
      this.init()
    }
  },
  mounted() {
    this.init()
    this.config.loading = false
  },
  methods: {
    init(){
      aSyncData('wallet/transactions')
          .then(r => {
            if(r.status === 250) {
              this.transactions = r.data.transactions
              this.user.credits = r.data.credits
              this.loadingTransaction = false
            }
          })
          .catch(e => {console.log(e)})
    },
    getTransactionTitle(transaction){
      try {
        switch (transaction.meta.type){
          case 'checkout Paypal payment':
                return 'Guthaben via Paypal'
          case 'tip':
                return 'Trinkgeld' + (transaction.meta.userFirstname ? ' - ' + transaction.meta.userFirstname : '')
          case 'drinks':
                return transaction.title + ' - ' + 'Getränke & Co.'
          case 'tickets':
                return transaction.title + ' - ' + 'Tickets'
          default:
                return 'Transaktion'
        }
      }catch (e) {
        return 'Transaktion'
      }
    },
    generatePayQRCode() {
      aSyncData('wallet/createPaymentIntent')
          .then(r => {
            if(r.status === 250) {
              this.paymentModal = true
              this.paymentIntent = r.data
              this.$nextTick(() => {
                new Qrious({
                  element: document.getElementById('qrious'),
                  size: 350,
                  value: this.paymentIntent,
                  background: 'transparent',
                  foreground: '#ffffff',
                  level: 'H',
                });
              })
            }
          })
          .catch(e => {console.log(e)})
    },
    tip(){
      aSyncData('checkout/tip', {
        paymentIntentHash: this.paymentIntent.split('.')[0],
        check: 1
      })
          .then(r => {
            if(r.status === 250) {
              this.paymentAmount = -r.data / 100
              this.checkOutModal = false
              this.tipModal = true
            }else if(r.status === 285){
              this.transactionInfo = 'Kauf noch nicht abgeschlossen'
            }
          })
          .catch(e => {console.log(e)})
    }
  }
}
</script>

<style scoped>
#walletHeader{
  padding-top: 16px;
}
#walletHeader h1{
  font-size: 2em;
  font-weight: 300;
}
#walletHeader button{
  font-size: 1em;
  padding: 8px;
}
#walletCTA button{
  width: 100%;
}
.paymentModal{
  position: fixed;
}
.paymentModal .vueModalInner{
  min-width: 80vw;
}
.paymentModal h2{
  font-size: 2em;
}
.paymentModal h3{
  font-size: 1.2em;
  margin-bottom: 16px;
}
#chargeModal{
  position: fixed;
}
#chargeModal .vueModalInner{
  min-width: 80vw;
}
#chargeModal h2{
  font-size: 1.8em;
  font-weight: 300;
}
#chargeModal h3{
  font-size: 1.2em;
  margin-bottom: 16px;
}
#chargeModal .selectionButtons{
  max-width: 250px;
  margin: 0 auto;
}
#chargeModal .selectionButtons button{
  padding: 8px;
  font-size: 1.0em;
  margin-bottom: 32px;
}
#chargeModal #chargeInput{
  padding: 16px;
  width: calc(100% - 20px);
  max-width: 200px;
  background: #000000;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 1.2em;
}
#chargeModal .field span{
  font-size: 1.5em;
  margin-left: 8px;
}
#paymentHistory h2{
  font-size: 1.2em;
}
.paymentItem{
  margin-top: 8px;
  padding: 12px;
  border-radius: 10px;
  background-color: var(--second-bg-color);
}
.paymentItem .paymentItemAmount .t-right{
  font-size: 1.2em;
}
.paymentItem .paymentItemAmount .t-right.negative{
  color: darkred;
}
.paymentItem .paymentItemAmount .t-right.positive{
  color: green;
}
.paymentItem .paymentItemDate{
  color: rgba(255,255,255,0.6);
  font-size: 0.8em;
}

.qrcode img{
  width: 100%;
  height: auto;
  max-width: 300px;
}

.flex{
  display: flex;
}

.buttons-wrapper{
  flex-wrap: wrap;
  column-gap: 100%;
  row-gap: 8px;
}

.button-primary{
  width: 100%;
}

</style>