<template>
  <main className="content-500" id="media">
    <div class="title">
      <h1>Medien</h1>
      <h2>Bilder & Videos</h2>
    </div>
    <section>
      <div class="grid-12">
        <div class="col-sp-6 row-sp-12">
          <button class="pad-16 t-center tabMenu" :class="[page === 'all' ? 'active' : '']" @click="page = 'all'">
            Alle Medien
          </button>
        </div>
        <div class="col-sp-6 row-sp-12">
          <button class="pad-16 t-center tabMenu" :class="[page === 'albums' ? 'active' : '']" @click="page = 'albums';slider= true">
            Alben
          </button>
        </div>
        <div>

        </div>
      </div>
    </section>
    <router-view v-slot="{Component}">
      <section class="pad-16" v-if="page === 'all'">
          <media :media="media"/>
        <div v-if="!media.length" class="t-center">
          <p class="color-white">
            Keine Medien vorhanden.
          </p>
        </div>
      </section>
      <keep-alive>
        <component :is="Component" v-if="page === 'albums'" />
      </keep-alive>
    </router-view>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import media from "@/components/eventDetails/media";
import {aSyncData} from "@/modules/main";
import PicSlider from "@/components/eventDetails/picSlider.vue";


export default {
  name: "MediaView",
  title: "Media",
  components: {
    PicSlider,
    media,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      media: [],
      page: this.$route.params.eventId || this.$route.query.tab === 'albums' ? 'albums' : 'all',
      slider:false,
    }
  },
  mounted() {
    this.config.loading = false
    this.loadMedia()
  },
  methods: {
    loadMedia(){
      aSyncData('media/getMedia')
          .then(r => {
            if(r.status === 250){
              this.media = r.data.media
              console.log(this.media)

            }
          })
    },
  }
}
</script>

<style>
#media .title h1{
  margin-top: 16px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}
#media .title h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  text-align: center;
  margin-bottom: 32px;
}
#media .tabMenu{
  cursor: pointer;
  transition: 0.3s;
  background-color: var(--second-bg-color);
  border: none;
  border-bottom: var(--second-bg-color) 3px solid;
  color: #ffffff;
  display: block;
  width: 100%;
  font-weight: bold;
}
#media .tabMenu:hover{
  background-color: #000000;
  border-bottom: #000000 3px solid;
}
#media .tabMenu.active{
  border-bottom: #2068ff 3px solid;
}
</style>