<template>
  <div style="margin-top: 30px; overflow: auto" v-if="tipStatus !== 'fulfilled'">
    <h3 class="f-bold" style="font-size: 1.2em;">
      Betrag auswählen
    </h3>
    <div class="field chargeInput-wrapper">
      <input id="chargeInput" type="number" step="0.01" v-model="value"><span>€</span>
    </div>
    <div class="grid-12 t-center selectionButtons">
      <div class="col-sp-4 row-sp-12">
        <button @click="value = Math.round(amount * 5) / 100" class="button color-white" :class="{active: value === Math.round(amount * 5) / 100}">
          5%
        </button>
      </div>
      <div class="col-sp-4 row-sp-12">
        <button @click="value = Math.round(amount * 10) / 100" class="button color-white" :class="{active: value === Math.round(amount * 10) / 100}">
          10%
        </button>
      </div>
      <div class="col-sp-4 row-sp-12">
        <button @click="value = Math.round(amount * 20) / 100" class="button color-white" :class="{active: value === Math.round(amount * 20) / 100}">
          20%
        </button>
      </div>
    </div>
    <payment-type :amount="value" @total="credits => {useCredits = credits}" :check-credits="true" />
    <hr class="separator">
    <div>
      <p class="error" v-if="error">
        {{ error }}
      </p>
      <button class="button bgcolor-blue color-white fluid" type="button" @click="checkOut" :disabled="!value" :class="{disabled: !value}">
        Betrag spendieren
      </button>
    </div>
  </div>
  <loader size="small" v-if="loading" />
</template>

<script>
import {aSyncData, dateToString, stringToDate} from "@/modules/main";
import Loader from "@/components/basic/loader";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import PaymentType from "@/components/payment/paymentType";

export default {
  name: "tip",
  components: {Loader, PaymentType},
  props: {
    amount: {
      type: Number,
      default: 10
    },
    paymentIntentHash: {
      type: String,
      required: true
    }
  },
  setup() {
    const auth = authStore()
    const config = configStore()

    return {auth, config}
  },
  data(){
    return{
      loading: false,
      value: Math.round(this.amount * 10) / 100,
      error: '',
      useCredits: 0,
      tipStatus: 'open'
    }
  },
  mounted() {
  },
  methods: {
    checkOut(){
      if(!this.value) return
      this.loading = true
      this.error = ''
      aSyncData('checkout/tip', {
        tipAmount: this.value,
        useCredits: this.useCredits,
        paymentIntentHash: this.paymentIntentHash
      })
          .then(r => {
            console.log(r)
            if(r.status === 250) {
              if(r.data.link) {
                location.href = r.data.link
              }else if(r.data.cipher){
                this.$router.push({name: 'Checkout', params: {cipher: r.data.cipher}})
              }
            }else if(r.status === 285){
              this.error = 'Kauf noch nicht abgeschlossen'
            }else{
              //TODO noch auf deutsch übersetzen
              this.error = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            this.loading = false
          })
    },
  }
}
</script>

<style scoped>
.smallHint{
  font-size: 0.8em;
}


#chargeInput {
  padding: 8px;
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  border: none;
  background-color: #1E1E1E;
  color: white;
}

.chargeInput-wrapper{
  margin: 8px 0;
  display: flex;
  align-items: baseline;
  column-gap: 12px;
  justify-content: center;
}

.chargeInput-wrapper #chargeInput {
  width: 40%;
}

.selectionButtons{
  column-gap: 12px;
}

.selectionButtons button{
  margin-top: 8px;
  padding: 12px;
  border-radius: 10px;
  background-color: #1E1E1E;
  width: 100%;
}

.selectionButtons button:hover{
  background-color: #2068ff !important;
  opacity: 1 !important;
}

.disabled,
.disabled:hover{
  background-color: var(--second-bg-color);
}

.separator{
  opacity: 0.1;
  margin: 16px 0;
}

.button{
  background-color: #2068ff;
  margin-top: 12px;
}
</style>