<template>
  <div>
    <div style="padding-top: 100px; padding-bottom: 120px;">
      <div class="lounge-header">
        <div style="padding: 0 16px;">
          <h2>Lounge</h2>
          <div v-if="user.activeLounges.length < 2" style="margin-top: 12px;">
            {{ user.lounges[0].title }}
          </div>
          <select v-else v-model="loungeId" class="loungeSelect" style="margin-top: 12px;">
            <option v-for="lounge in user.activeLounges" :key="lounge.id" :value="loungeId">
              {{ lounge.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="pad-16">
        <div v-for="category in categories" :key="category.barCategoryId" class="orderOverview">
          <h1 style="font-weight: bold; margin: 20px 0 12px; font-size: 1.2em">
            {{ category.categoryName }}
          </h1>
          <div v-for="item in items.filter(i => i.barCategoryId === category.barCategoryId).sort((a,b) => a.listOrder - b.listOrder)" :key="item.id" class="itemOrder">
            <div class="grid-12">
              <div class="col-sp-4 row-sp-12">
                <div class="itemName">
                  <p>{{ item.name }}</p>
                </div>
              </div>
              <div class="col-sp-5 row-sp-12">
                <div class="itemDescription">
                  <p>{{ item.caption }}</p>
                </div>
              </div>
              <div class="col-sp-2 row-sp-12">
                <div class="itemPrice">
                  <p>{{ item.price.toFixed(2) }}€</p>
                </div>
              </div>
              <div class="col-sp-1 row-sp-12">
                <div class="itemAdd" @click="addToBucket(item); splash($event)">
                  <span style="font-weight: 600; display: block">+</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="bucket">
      <div class="order" :style="{'height': bucketShow ? '75%' : '39px'}" v-if="bucketInit">
        <div class="orderName" @click="bucketShow = !bucketShow">
          Warenkorb {{ price.netto }}€ ({{ bucket.map(item => item.quantity).reduce((prev, next) => prev + next, 0) }})
          <img class="orderShow" src="/assets/images/icons/arrow-left.svg" :style="'transform: rotate(' + (bucketShow ? '-' : '+') + '90deg)'">
        </div>
        <div class="orderList">
          <div v-if="bucket.length">
            <div v-for="category in orderCategories" :key="category.barCategoryId">
              <h1 style="font-weight: bold; padding-bottom: 6px;">
                {{ category.categoryName }}
              </h1>
              <hr>
              <div v-for="item in bucket.filter(i => i.barCategoryId === category.barCategoryId).sort((a,b) => a.listOrder - b.listOrder)" :key="item.id" class="itemOrder">
                <div class="itemNamePriceQuantity" style="display: flex; column-gap: 100%; flex-wrap: wrap; justify-content: left">
                  <div class="itemName">
                    {{ item.name }}
                  </div>
                  <div style="display: flex; column-gap: 8px;">
                    <div class="itemQuantity">
                      {{ item.quantity }}x
                    </div>
                    <div class="itemSinglePrice">
                      {{ item.price.toFixed(2) }}€
                    </div>
                  </div>
                </div>
                <div class="itemPrice">
                  {{ (item.price * item.quantity).toFixed(2) }}€
                </div>
                <div class="itemDelete" @click="removeFromBucket(item.id)">
                  <span style="font-weight: 600; display: block">-</span>
                </div>
              </div>
            </div>
            <hr class="separator">
            <coupon :items="couponItems" @coupon="c => coupon = c" />
            <hr class="separator">
            <h2 style="font-size: 1.2em; padding-bottom: 8px;">
              Möchtest du trinkgeld geben?
            </h2>
            <input class="individualTipInput" type="number" step="0.1" v-model="tip">€
            <div class="individualTip-wrapper">
              <button @click="tip = 0" :class="{active: tip === 0}">
                0%
              </button>
              <button @click="tip = Math.round(price.netto * 5) / 100" :class="{active: tip === Math.round(price.netto * 5) / 100}">
                5%
              </button>
              <button @click="tip = Math.round(price.netto * 10) / 100" :class="{active: tip === Math.round(price.netto * 10) / 100}">
                10%
              </button>
            </div>
            <hr class="separator">
            <payment-type :amount="price.total" @total="credits => {useCredits = credits}" />
            <hr class="separator">
            <button @click="order" class="button-primary" style="width: 100%; margin-bottom: 70px">
              {{ (price.total - useCredits/100).toFixed(2) }}€ Bestellen
            </button>
          </div>
          <div v-else>
            Bitte füge Produkte deiner Bestellung hinzu
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {aSyncData} from "@/modules/main";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import paymentType from "@/components/payment/paymentType";
import coupon from "@/components/payment/coupon";

export default {
  name: "loungeOrderView",
  title: "Getränke Bestellen",
  components: {
    paymentType,
    coupon
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      loungeId: 0,
      items: [],
      bucketInit: false,
      bucketShow: false,
      bucket: [],
      coupon: {},
      useCredits: 0,
      tip: 0
    }
  },
  computed: {
    categories(){
      return [...new Map(this.items.map(item => [item['barCategoryId'], item])).values()].sort((a,b) => {
        if(a.categoryOrder < b.categoryOrder){
          return -1
        }else if(a.categoryOrder > b.categoryOrder){
          return 1
        }
        return a.barCategoryId <= b.barCategoryId
      })
    },
    orderCategories(){
      return [...new Map(this.bucket.map(item => [item['barCategoryId'], item])).values()].sort((a,b) => {
        if(a.categoryOrder < b.categoryOrder){
          return -1
        }else if(a.categoryOrder > b.categoryOrder){
          return 1
        }
        return a.barCategoryId <= b.barCategoryId
      })
    },
    couponItems(){
      const drinks = {}
      this.bucket.forEach(b => {
        drinks[b.id] = b
      })
      return {drinks: drinks}
    },
    price(){
      const price = {}
      price.subTotal = ((Math.round(this.bucket.map(item => item.quantity * item.price).reduce((prev, next) => prev + next, 0)*100))/100)
      price.discount = 0

      if(this.coupon.discount){
        price.discount = this.coupon.discount
      }

      price.discount = price.discount.toFixed(2)
      price.netto = (Math.round(Math.max(0, price.subTotal - price.discount) * 100) / 100)
      price.total = (Math.round((Math.max(0, price.subTotal - price.discount) + this.tip) * 100)/100)
      console.log(price, price.subTotal + this.tip)
      return price
    }
  },
  mounted() {
    if(!this.user.activeLounges.length){
      this.$router.push('/tickets')
    }else{
      this.loungeId = this.user.activeLounges[0].id
      this.loadBarMenu()
    }
    setTimeout(() => {this.bucketInit = true}, 500)
  },
  beforeUnmount() {
    this.bucketInit = false
  },
  methods: {
    loadBarMenu(){
      aSyncData('lounge/getBarMenu', {secureCode: this.user.activeLounges.find(aL => aL.id === this.loungeId).secureCode})
          .then(r => {
            console.log(r.data)
            if(r.status === 250) this.items = r.data
          })
          .catch(e => {console.error(e)})
    },
    addToBucket(item){
      let temp = this.bucket.find(b => b.id === item.id)
      if(temp){
        temp.quantity++
      }else{
        item.quantity = 1
        this.bucket.push(item)
      }
    },
    removeFromBucket(itemId){
      try {
        let item = this.bucket.find(b => b.id === itemId)
        if(item.quantity > 1){
          item.quantity--
        }else{
          this.bucket = this.bucket.filter(b => b.id !== itemId)
        }
      }catch (e) {console.error(e)}
    },
    order(){
      this.loading = true
      this.error = ''
      aSyncData('checkout/drinks', {
        secureCode: this.user.activeLounges.find(aL => aL.id === this.loungeId).secureCode,
        couponId: this.coupon.id ?? null,
        currentSite: this.$route.path,
        items: {drinks: this.bucket, tip: [{price: this.tip}]},
        subTotal: this.price.subTotal + this.tip,
        total: this.price.total,
        useCredits: this.useCredits,
        payPal: this.price.total - this.useCredits/100
      })
          .then(r => {
            console.log(r)
            if(r.status === 250) {
              if(r.data.link) {
                location.href = r.data.link
              }else if(r.data.cipher){
                this.$router.push({name: 'Checkout', params: {cipher: r.data.cipher}})
              }
            }else{
              //TODO noch auf deutsch übersetzen
              this.error = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            this.loading = false
          })
    },
    splash(e){
      e.target.parentNode.parentNode.classList.add('splash')
      setTimeout(() => {
        e.target.parentNode.parentNode.classList.remove('splash')
      },250)
    }
  }
}
</script>

<style scoped>
.orderOverview .itemOrder{
  margin: 6px 0;
  align-items: center;
  background-color: var(--second-bg-color);
  padding: 12px;
  border-radius: 10px;
}
.orderOverview .itemOrder .itemName{
  flex: 2;
}

.orderOverview .itemOrder .itemDescription{
  flex: 0.8;
}

.orderOverview .itemOrder .itemPrice{
  flex: 1;
}

.orderOverview .itemOrder .itemAdd{
  background-color: #2068ff;
  display: flex;
  justify-content: center;
  padding: 4px 6px;
  border-radius: 4px;
}

.order{
  position: fixed;
  bottom: 62px;
  text-align: center;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.75s ease;
}

.order .itemOrder{
  display: flex;
  flex-wrap: wrap;
  margin: 6px 0;
  align-items: center;
}

.order .itemNamePriceQuantity{
  flex: 2;
}

.order .itemPrice{
  flex: 1;
}

.order .itemName {
  text-align: left;
}

.order .itemDelete{
  background-color: #1E1E1E;
  display: flex;
  justify-content: center;
  padding: 4px 6px;
  border-radius: 4px;
  width: 12px;
}

.orderName{
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: black;
  padding: 10px;
}
.orderList{
  background-color: var(--main-bg-color);
  padding: 10px;
  height: 100%;
  overflow: auto;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
img.orderShow{
  height: 20px;
  filter: brightness(0%);
  float: right;
  transition: all 0.75s ease;
}
.separator{
  opacity: 0.1;
  margin: 16px 0;
}

.individualTip-wrapper button{
  margin: 8px 4px 0;
  padding: 12px;
  border-radius: 10px;
  background-color: #1E1E1E;
  border: none;
  color: white;
  width: 80px;
}

.individualTipInput {
  padding: 8px;
  width: 40%;
  max-width: 300px;
  border-radius: 8px;
  border: none;
  background-color: #1E1E1E;
  color: white;
}

.lounge-header{

}

.lounge-header h2{
  font-size: 2em;
  font-weight: 300;
}

.loungeSelect{
  background-color: var(--second-bg-color);
  color: white;
  border: none;
  padding: 12px;
  border-radius: 10px;
  width: 100%;
}
.splash{
  animation: splash 0.25s normal forwards ease-in-out;
}
@keyframes splash {
  from {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  to {
    transform: scale(1, 1);
  }
}

.bucket-enter-active{
  transition: all 0.5s ease-out;
}
.bucket-enter-from,
.bucket-leave-to {
  opacity: 0;
}
</style>