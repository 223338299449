<template>
  <transition name="modal">
    <div class="vueModal" v-if="show">
      <div class="vueModalOuter" @click="$emit('close')" />
      <div class="vueModalInner" :class="size">
        <div class="t-center pad-8" style=" max-height: 88vh">
          <slot />
        </div>
        <div class="pad-16 t-center">
          <button class="button bgcolor-back color-white" @click="$emit('cancel')" v-if="cancel" :disabled="cancelDisabled" :class="{disabled: cancelDisabled}">
            {{ cancel }}
          </button>
          <button class="button bgcolor-blue color-white" @click="$emit('approve')" v-if="approve" :disabled="approveDisabled" :class="{disabled: approveDisabled}">
            {{ approve }}
          </button>
          <button class="button bgcolor-blue color-white" @click="$emit('option1')" v-if="option1" :disabled="option1Disabled" :class="{disabled: option1Disabled}">
            {{ option1 }}
          </button>
          <button class="button bgcolor-blue color-white" @click="$emit('option2')" v-if="option2" :disabled="option2Disabled" :class="{disabled: option2Disabled}">
            {{ option2 }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    maxSize: {
      type: String,
      default: 'medium'
    },
    minSize: {
      type: String,
      default: 'small'
    },
    show: {
      type: Boolean,
      required: true
    },
    cancel: {
      type: String,
      required: false
    },
    cancelDisabled: {
      type: Boolean,
      required: false
    },
    approve: {
      type: String,
      required: false
    },
    approveDisabled: {
      type: Boolean,
      required: false
    },
    option1: {
      type: String,
      required: false
    },
    option1Disabled: {
      type: Boolean,
      required: false
    },
    option2: {
      type: String,
      required: false
    },
    option2Disabled: {
      type: Boolean,
      required: false
    },
  },
  emits: ['cancel', 'close', 'approve', 'option1', 'option2'],
  computed: {
    size(){
      return {
        [this.maxSize + 'Max']: true,
        [this.minSize + 'Min']: true,
      }
    }
  }
}
</script>

<style scoped>
.vueModal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px) brightness(0.5);
}
.vueModalOuter{
  position: absolute;
  width: 100vh;
  height: 100vh;
  top: 0;
  left: 0;
}
.vueModalInner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 20px;
  border-radius: 10px;
  background-color: #121212;
  max-height: 90vh;
  max-width: 90vh;
  overflow: auto;
}
.button{
  text-align: center;
  margin: 4px;
}
.disabled{
  background-color: grey !important;
}
.smallMax{
  max-width: 200px !important;
  max-height: 30vh !important;;
}
.mediumMax{
  max-width: 400px !important;;
  max-height: 60vh !important;;
}
.largeMax{
  max-width: 90vw !important;;
  max-height: 90vh !important;;
}
.smallMin{
  min-width: 280px !important;
  min-height: 30vh !important;;
}
.mediumMin{
  min-width: 400px !important;;
  min-height: 60vh !important;;
}
.largeMin{
  min-width: 90vw !important;;
  min-height: 90vh !important;;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
@media (max-width: 768px){
  .vueModalInner{
    max-width: 100vw;
    width: 100vw;
    max-heiht: 100vh;
    height: 100vh;
  }
}
</style>