<template>
  <div style="margin-top: 200px">
    <loader size="small" v-if="loading" />
    <div style="overflow: auto" v-else-if="finished">
      <h2 style="text-align: center; margin-bottom: 10px;">
        Dein Kauf war erfolgreich!
      </h2>
      <p style="text-align: center; margin-bottom: 20px; font-weight: 600">
        Jetzt downloaden!
      </p>
      <div class="flex downloadButton-wrapper">
        <button class="button-primary" v-if="tickets">
          <download-p-d-f :link="item.encrypted" v-for="item in tickets" :key="item.name" name="Tickets" :file-name="item.name" target="" file-type="ticket" />
        </button>
        <button class="button-primary">
          <download-p-d-f :link="invoice.encrypted" name="Rechnung" target="" file-type="invoice" />
        </button>
      </div>

      <div v-if="config.getMobileOperatingSystem === 'iOS' && passBooks">
        <hr class="separator">
        <h3 style="text-align: center">
          Add to Wallet
        </h3>
        <div style="text-align: center; margin-top: 20px; margin-bottom: 12px;">
          <add-to-wallet :file="item" v-for="item in passBooks" :key="item.name" target="" file-type="passBook" />
        </div>
      </div>
    </div>
    <div v-else style="text-align: center">
      Da ging was schief.
    </div>
  </div>
</template>

<script>
import {aSyncData} from "@/modules/main";
import Loader from "@/components/basic/loader";
import DownloadPDF from "@/components/payment/downloadPDF";
import AddToWallet from "@/components/payment/addToWallet";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "checkoutFinishedView",
  components: {DownloadPDF, AddToWallet, Loader},
  props: {
    cipher: {
      type: String
    }
  },
  setup() {
    const config = configStore()
    const user = userStore()

    return {config, user}
  },
  data(){
    return{
      loading: true,
      invoice: {},
      tickets: {},
      passBooks: [],
      finished: false,
      interval: null
    }
  },
  mounted() {
    this.getFiles()
    this.interval = setInterval(this.getFiles, 2000)
  },
  methods: {
    getFiles(){
      aSyncData('checkout/getData', {cipher: this.cipher})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.finished = true
              this.invoice = r.data.invoice
              this.tickets = r.data.tickets
              this.passBooks = r.data.passBooks
              this.user.credits = r.data.credits
              this.loading = false
              clearInterval(this.interval)
            }else if(r.status === 260){
              this.loading = false
              clearInterval(this.interval)
            }
          })
          .catch(e => {console.error(e)})
    }
  }
}
</script>

<style scoped>

  .flex {
    display: flex;
  }
  .downloadButton-wrapper{
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    flex-wrap: wrap;
  }

  .button-primary{
    width: 70%;
    border: none;
  }

  .separator{
    opacity: 0.1;
    margin: 16px 0;
  }
</style>