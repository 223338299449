<template>
  <a target="_blank" :href="config.projectURL + 'checkout/getFile?type=' + fileType + '&file=' + link + (fileName ? '&name=' + fileName : '')">
    <div class="flex buttonContent">
      <img src="/assets/images/icons/pdf.png" style="width: 100%; max-width: 20px;">
      <span>{{ name }}</span>
    </div>
  </a>
</template>

<script>
import {configStore} from "@/store/configStore";

export default {
  name: "downloadPDF",
  props: {
    link: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    fileType: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: false
    }
  },
  setup() {
    const config = configStore()

    return {config}
  },
}
</script>

<style scoped>
  .flex{
    display: flex;
  }
  .buttonContent{
    justify-content: center;
    align-items: center;
    column-gap: 8px;
  }

</style>