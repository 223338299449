<template>
  <article class="pad-4 friendlistItem">
    <div class="grid-12">
      <div class="col-sp-3 row-sp-12">
        <img :src="'assets/images/users/'+friendlistItems.userimg" class="width-28 circular">
      </div>
      <div class="col-sp-6 row-sp-12 t-left">
        <h6>{{ friendlistItems.firstname }}</h6>
      </div>
      <div class="col-sp-3 row-sp-12 t-right" style="padding-top: 4px;">
        <button class="tiny button bgcolor-black color-white" @click="deleteFriend(friendlistItems.userid);">
          Entfernen
        </button>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "FriendlistItems",
  props: ["friendlistItems"],
  emits: ['update'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    deleteFriend(userid){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('friendId', userid)
      this.axios.post('profil/deleteFriend', fD)
          .then((response) => {
            if(response.status === 250){
              this.$emit('update');
            }
          })
    }
  }
}
</script>

<style scoped>

.friendlistItem h6{
  font-weight: 600;
  padding-top: 8px;
}
.friendlistItem button{
  font-size: 0.7em;
  text-transform: none;
}
</style>