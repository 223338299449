<template>
  <main class="content-500" id="organizer">
    <section class="t-center">
      <h1>Willkommen bei</h1>
      <h2>{{ organizerDetails.organizerName }}</h2>
    </section>
    <section id="organizerHeader">
      <img :src="'assets/images/eventhoster/' + organizerDetails.logo" class="image width-150 circular">
      <div class="profilheaderInfos">
        <button class="bgcolor-second color-white button sharebutton">
          <img src="assets/images/icons/share-white.svg" class="width-20">
        </button>
        <button v-if="!isGetIsSub == true" class="button followButton bgcolor-blue color-white br-8" @click="addSub()">
          Folgen
        </button>
        <button v-else class="button followButton bgcolor-black color-white br-8" @click="removeSub()">
          Entfolgen
        </button>
      </div>
    </section>
    <section id="organizerLabels" class="t-center">
      <labelItems v-for="litems in labelItems" :label-items="litems" :key="litems.id" />
    </section>
    <section id="organizerDescription" class="pad-16">
      <div class="bgcolor-second t-center color-white br-10 pad-16">
        <p>
          <b class="color-orange title f-bold">Beschreibung</b>
          {{ organizerDetails.aboutText }}
        </p>
      </div>
    </section>
    <section id="nextPartys" class="pad-16">
      <h3>
        Die nächsten <span class="color-blue f-bold">Partys</span>
      </h3>
      <div id="nextPartysItems">
        <otherEventsItems v-for="oeitems in otherEventsItems" :other-events-items="oeitems" :key="oeitems.id" />
      </div>
    </section>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import otherEventsItems from "@/components/eventDetails/otherEvents";
import labelItems from "@/components/organizer/label";

export default {
  name: "Organizer",
  title: "Organizer",
  components: {
    'otherEventsItems': otherEventsItems,
    'labelItems': labelItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      otherEventsItems: '',
      organizerDetails: '',
      labelItems: '',
      isGetIsSub: false,
    }
  },
  mounted() {
    this.config.loading = false
    this.getOrganizerDetails()
    this.getOtherEventsItems()
    this.getIsSub()
  },
  methods: {
    getOtherEventsItems(){
      const _this = this
      const fD = new FormData()
      fD.append('ownerId', this.$route.params.id)
      this.axios.post('organizer/getOtherEventsItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.otherEventsItems = response.data
            }
          })
    },
    getOrganizerDetails(){
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.id)
      this.axios.post('organizer/getOrganizerDetails', fD)
          .then((response) => {
            if(response.status === 250){
              _this.organizerDetails = response.data
              this.getLabelItems(response.data.labels)
            }
          })
    },
    getLabelItems(labelIds){
      const _this = this
      const fD = new FormData()
      fD.append('labelIds', labelIds)
      this.axios.post('organizer/getLabelItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.labelItems = response.data
            }
          })
    },
    getIsSub() {
      const _this = this
      const fD = new FormData()
      fD.append('organizerId', this.$route.params.id)
      this.axios.post('organizer/getIsSub', fD)
          .then((response) => {
            if(response.status === 250){
              _this.isGetIsSub = true
            }else{
              _this.isGetIsSub = false
            }
          })
    },
    addSub() {
      const _this = this
      const fD = new FormData()
      fD.append('organizerId', this.$route.params.id)
      this.axios.post('organizer/addSub', fD)
          .then(() => {
            _this.getIsSub()
          })
    },
    removeSub() {
      const _this = this
      const fD = new FormData()
      fD.append('organizerId', this.$route.params.id)
      this.axios.post('organizer/removeSub', fD)
          .then(() => {
            _this.getIsSub()
          })
    },
  }
}
</script>

<style>
main#organizer{
  padding-bottom: 80px;
  padding-top: 0!important;
}
main#organizer h1{
  margin-top: 64px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
}
main#organizer h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  margin-bottom: -16px;
}
#organizerHeader{
  text-align: center;
  padding-top: 40px;
}
#organizerHeader img{
  border: 2px solid #ffffff;
  position: relative;
  z-index: 1;
  aspect-ratio: 1/1;
}
#organizerHeader .profilheaderInfos{
  background-color: var(--second-bg-color);
  width: calc(100% - 32px);
  margin: 0 auto;
  border-radius: 8px;
  padding-top: 100px;
  position: relative;
  z-index: 0;
  margin-top: -80px;
  padding-bottom: 16px;
  margin-bottom: 32px;
}
#organizerHeader .profilheaderInfos button.sharebutton{
  position: absolute;
  right: 8px;
  top: 8px;
}
#organizerHeader .profilheaderInfos button.sharebutton img{
  border: none;
}
#organizerHeader .profilheaderInfos button.followButton{
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 48px;
}
#organizerLabels{
  padding: 16px;
}
#organizerDescription .title{
  display: block;
  margin-bottom: 8px;
}
#nextPartys h3{
  margin-top: 32px;
  font-size: 1.4em;
}
#nextPartysItems{
  margin-top: 64px;
}
</style>