<template>
  <article class="ticket">
    <div class="grid-12" style="background-color: black; border-radius: 10px;">
      <div class="col-sp-12 row-sp-12 ticketBox">
        <div class="availability">
          <span v-if="(lounge.quantity - lounge.reserved) < 1" class="button bgcolor-red color-white tiny">Ausverkauft</span>
          <span v-else-if="(lounge.quantity - lounge.reserved) <= 2" class="button bgcolor-red color-white tiny">Nur noch {{ lounge.quantity - lounge.reserved }} verfügbar</span>
        </div>
        <img src="https://static.skyteam.com//contentapi/globalassets/lounges/scl/scl_880_530.jpg">
        <div class="overlay">
          <h3>{{ lounge.title }}</h3>
        </div>
      </div>
      <div class="col-sp-12 row-sp-12">
        <div class="grid-12">
          <div class="col-sp-12 row-sp-12">
            <p class="ticketDescription" v-html="nl2br(lounge.description)" />
          </div>
          <div class="col-sp-4 row-sp-12">
            <div class="bgcolor-black color-white br-10 pad-8 t-center">
              <h4 style="font-size: 1.5em; margin: 8px 0 0 0;">
                {{ lounge.maxGuests }}
              </h4>
              <h5 style="font-size: 1em; font-weight: normal; margin: 0 0 8px 0;">
                Personen
              </h5>
            </div>
          </div>
          <div class="col-sp-4 row-sp-12">
            <div class="bgcolor-black color-white br-10 pad-8 t-center">
              <h4 style="font-size: 1.5em; margin: 8px 0 0 0;">
                {{ lounge.price }}€
              </h4>
              <h5 style="font-size: 1em; font-weight: normal; margin: 0 0 8px 0;">
                Mindestverzehr
              </h5>
            </div>
          </div>
          <div class="col-sp-4 row-sp-12">
            <div class="bgcolor-black color-white br-10 pad-8 t-center">
              <h4 style="font-size: 1.5em; margin: 8px 0 0 0;">
                ={{ (lounge.price / lounge.maxGuests).toFixed(2) }}€
              </h4>
              <h5 style="font-size: 1em; font-weight: normal; margin: 0 0 8px 0;">
                pro Person
              </h5>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-4" v-if="(lounge.quantity - lounge.reserved) > 0">
            <button class="bgcolor-blue fluid button color-white loungeReservationButton" :disabled="loading" :class="{disabled: loading}" @click="checkOutModal = true">
              Lounge reservieren
            </button>
          </div>
          <div class="col-sp-12 row-sp-12">
            <div class="availableLounges">
              {{ (lounge.quantity - lounge.reserved) + '/' + lounge.quantity }} verfügbar
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--      <div class="col-sp-3 row-sp-12">-->
    <!--        <img :src="config.projectURL + 'assets/images/lounges/' + lounge.image + '_thumb.jpg'" v-if="lounge.image">-->
    <!--      </div>-->
  </article>
  <VueModal :show="checkOutModal" @close="checkOutModal = false" min-size="small" max-size="large">
    <check-out :item="lounge" checkout-type="lounges" />
  </VueModal>
</template>

<script>
import CheckOut from "@/components/payment/checkOut";
import {configStore} from "@/store/configStore";
import {nl2br} from "@/modules/main";

export default {
  name: "lounge",
  components: {CheckOut},
  props: {
    lounge: {
      type: Object,
      required: true
    }
  },
  setup(){
    const config = configStore()

    return {config}
  },
  data(){
    return{
      checkOutModal: false,
      loading: false
    }
  },
  methods:{
    nl2br(str){
      return nl2br(str)
    }
  }
}
</script>

<style scoped>

.ticket{
  margin-top: 8px;
  cursor: pointer;
  border-bottom: 2px solid rgba(255,255,255,0.05);
  border-radius: 10px;
  padding: 16px;
  transition: 0.3s;
}
.ticket h4{
  font-size: 1.1em;
  font-family: 'Helvetica';
  font-weight: 800!important;
  margin-top: 4px;
}
.ticket p.ticketDescription{
  color: rgba(255,255,255,0.8)!important;
  font-weight: 200;
  font-size: 1.1em;
  margin: 12px 0 4px;
  text-align: center;
}
.ticket h5{
  font-size: 1.4em;
  font-family: 'Helvetica';
  font-weight: 800;
  margin-bottom: 24px;
  margin-top: 16px;
}
.ticket h5 span{
  font-size: 0.7em;
}
.ticket button{
  margin-top: 16px;
}

.ticketBox{
  position: relative;
}

.ticketBox img{
  width: 100%;
  max-width: 1200px;
}

.ticketBox .overlay {
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  padding: 84px;
}

.ticketBox h3{
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 400;
  font-size: 1.4em;
  text-align: center;
}

.ticketBox .availability{
  top: -17px;
  right: -1px;
  z-index: 10;
  position: absolute;
  padding: 6px 10px;
  font-size: 0.8em;
}

.loungeReservationButton{
  width: 80% !important;
  text-align: center;
  margin: 0 auto 8px !important;
}

.availableLounges{
  text-align: center;
  margin-bottom: 16px;
  font-size: 0.9em;
}
</style>