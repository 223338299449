<template>
  <section class="pad-8">
    <loader size="small" v-if="loading" />
    <div class="pad-8 bgcolor-second br-10" v-else style="cursor: pointer; margin-bottom: 16px;">
      <div v-for="album in albums" :key="album.id" @click="$router.push({name: 'EventMediaView', params: {eventId: album.id}})">
        <img :src="config.projectURL + 'assets/images/events/' + album.image + '_thumb.jpg'" class="br-10 responsive-img">
        <div class="pad-16">
          <h3 style="font-size: 1.5em;" class="color-blue f-bold">
            {{ album.title }}
          </h3>
          <h4>{{ formatDate(album.startTime) }}</h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loader from "@/components/basic/loader";
import {aSyncData, dateToString, stringToDate} from "@/modules/main";
import {configStore} from "@/store/configStore";

export default {
  name: "eventOverview",
  components: {Loader},
  setup() {
    const config = configStore()

    return {config}
  },
  data() {
    return {
      albums: [],
      loading: true
    }
  },
  mounted() {
    this.loadEvents()
  },
  methods: {
    loadEvents(){
      aSyncData('media/getAlbums')
          .then(r => {
            if(r.status === 250) this.albums = r.data
          })
          .catch(e => {console.error(e)})
          .finally(() => this.loading = false)
    },
    formatDate(date){
      return dateToString(stringToDate(date)).split(' ')[1]
    }
  }
}
</script>

<style scoped>

</style>