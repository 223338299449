<template>
  <div style="width: 100vw;height: 100vh">
    <Swiper :slides-per-view="1" navigation :pagination="{ clickable: true }" :scrollbar="{ draggable: true }">
      <div v-for="singleMedia in media" :key="singleMedia.name">
        <swiper-slide>
          <div style="width: 80vw">
            <img width="" :src="config.projectURL + config.eventMediaUploadPath + singleMedia.eventId + '/' + singleMedia.name + '.' + singleMedia.extension">
          </div>
        </swiper-slide>
      </div>
    </Swiper>
  </div>

</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
  name: "picSlider",
  components:{
    Swiper,
    SwiperSlide,
  }
}
</script>

<style scoped>

</style>