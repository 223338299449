<template>
  <article class="event" @click="$router.push({name: 'EventDetails', params: { id: otherEventsItems.id }});">
    <div class="event-top">
      <div class="grid-12">
        <div class="col-sp-4 row-sp-12">
          <p class="promotion" v-if="otherEventsItems.promotion == 1">
            Gesponsert
          </p>
        </div>
        <div class="col-sp-8 row-sp-12 t-right">
          <div class="label button bgcolor-blue color-white">
            {{ otherEventsItems.label }}
          </div>
        </div>
      </div>
    </div>
    <img class="eventImage" :src="config.projectURL + 'assets/images/events/'+otherEventsItems.image+'.jpg'">
    <div class="imageCover" />
    <div class="eventWrapper">
      <div class="grid-12">
        <div class="col-sp-8 row-sp-12">
          <h4>{{ otherEventsItems.title }}</h4>
          <h5>{{ otherEventsItems.subTitle }}</h5>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "OtherEventsItems",
  props: ["otherEventsItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style scoped>
.event{
  position: relative;
  margin-bottom: 32px;
}
.event .event-top{
  margin-bottom: -16px;
  position: relative;
  z-index: 9;
  clear: both;
}
.event .event-top .label{
  padding: 10px 8px;
  text-transform: none;
  margin-right: 16px;
}
.event .event-top .promotion{
  font-size: 0.8em;
  color: rgba(255,255,255,0.6);
  font-family: 'Helvetica';
  padding-left: 4px;
}
.event .eventImage{
  max-width: 100%;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #ffffff;
}
.event .imageCover{
  position: absolute;
  width: 100%;
  height: calc(100% - 8px);
  border-radius: 10px;
  top: 2px;
  left: 2px;
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}
.event .eventWrapper{
  position: absolute;
  max-width: 100%;
  bottom: 32px;
  z-index: 4;
  left: 24px;
  right: 24px;
}
.event .eventWrapper h4{
  font-size: 1.6em;
  font-family: 'Helvetica';
  font-weight: 600;
  color: rgba(255,255,255,1);
  margin-bottom: 0px;
}
.event .eventWrapper h5{
  font-size: 1em;
  font-family: 'Helvetica';
  font-weight: 300;
  color: rgba(255,255,255,0.8);
}
</style>